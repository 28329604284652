















import { defineComponent } from '@nuxtjs/composition-api'
import {SfIcon } from '@storefront-ui/vue'
import { useUiState } from '~/composables';
import LoginModal from '~/modules/customer/components/LoginModal/LoginModal.vue';
export default defineComponent({
    components:{
        SfIcon,
        LoginModal
    },
    setup() {
        const { toggleLoginModal, isLoginModalOpen } = useUiState();
        return {
            toggleLoginModal,
            isLoginModalOpen
        }
        
    },
})
