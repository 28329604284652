import { render, staticRenderFns } from "./HeaderDropdown.vue?vue&type=template&id=2f186a5d&scoped=true"
import script from "./HeaderDropdown.vue?vue&type=script&lang=ts"
export * from "./HeaderDropdown.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f186a5d",
  null
  
)

export default component.exports