





import { defineComponent } from "@nuxtjs/composition-api"
import { addBasePath } from '~/helpers/addBasePath';
import {useLoader} from '~/composables/useLoader'
import Loader from '~/components/GlobalComponents/Loader.vue'
export default defineComponent({
  components:{
    Loader
  },
  props: {
    rootClass: {
      type: String,
      default: "bg-white text-black",
    },
    loaderColor:{
      type: String,
      default: 'black'
    }
  },
  setup(){
    const {isLoading} = useLoader()
    return {
        addBasePath,
        isLoading
    }
  }
});
