





import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
    props:{
        text:{
            type:String,
            required: true
        },
        bgClass: {
            type:String,
            default : 'bg-white'
        },
        classes:{
            type: Object, 
            default :()=>{ 
                return {
                    root:'', 
                    text:'text-xl lg:text-2xl text-dark',
                    beforeBgClass:'before:bg-black/50'
                }}
        }
    }
})
