















import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, onUpdated, ref } from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import PageLoading from '~/components/GlobalComponents/PageLoading.vue';
export default defineComponent({
  name: 'DefaultLayout',

  components: {
    PageLoading,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  setup() {
    const route = useRoute();
    const {getBodyBgColor} = useUiHelpers()
    

    const bodyColor = ref<String>(getBodyBgColor());

    onUpdated(()=>{
      bodyColor.value = getBodyBgColor();
    })

    return {
      route,
      bodyColor
    };
  },
});
