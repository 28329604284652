

























































import { defineComponent, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { LoginFormFields, FormName } from './types';
import { duHost, duHostUsers, duProvisioning } from '~/utils/constants/hosts';
import TextInput from '~/components/Inputs/Text.vue';
import { EMAIL_PATTERN } from '~/utils/constants/code';
import { SfCheckbox } from '@storefront-ui/vue';
import StripedString from '~/components/StripedString.vue';
export default defineComponent({
  metaInfo:{
    script : [{src: `${duProvisioning}/utenti/AuthFiles/SocialLogin/DUSocialLoginJS.ashx`, async: false, defer:false, body: true}]
  },
  components: {
    TextInput,
    SfCheckbox,
    StripedString
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    const emit = context.emit;
    const formCopy = ref<LoginFormFields>();
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };
    
    const {app} = useContext();

    const route = useRoute();
    switch(route.value.name){
      case 'home___rinnovi':
      formCopy.value.realSubmit = 'BYPOST';
      formCopy.value.RURL = window.location.href
      formCopy.value.ERRURL = window.location.href;//@TODO define
      formCopy.value.SC = "PR";
      break;
    }

    const showSocialLoginButtons = ref<Boolean>(false);
    
    const loadButtons = () =>{
      if (!!window['showSocialButtons']&& document.getElementById(window['_providersContainerID'])) {
        window['showSocialButtons']('EC', '', 'home2019.css');
        showSocialLoginButtons.value = true;
        clearInterval(socialInterval)
      }else loadButtons()
    }


    const socialInterval = setInterval(()=>{
      !showSocialLoginButtons.value && loadButtons();
    },500)

    setTimeout(()=>{
      clearInterval(socialInterval)
    }, 5000)

    const utmCampaign = route.value.query.utm_campaing
    const returnURL =  `${location.origin}/${location.pathname}`+(utmCampaign? `?utm_campaign=${utmCampaign}`:'' );

    return {
      formCopy,
      changeForm,
      duHost,
      showSocialLoginButtons,
      EMAIL_PATTERN,
      rememberMe: false,
      duHostUsers,
      returnURL
    };
  },
});
