import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseLoaderInterface } from '~/composables/useLoader/useLoader';

const state = reactive<StateInterface>({
  counterLoader: 0,
  isLoading: false
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useLoader(): UseLoaderInterface {
  
  const setIsLoading = (val: Boolean) => {
    val ? state.counterLoader++ : (state.counterLoader>0 ? state.counterLoader--:null);
  };


  return {
    isLoading: computed(() => state.counterLoader > 0),
    setIsLoading
  };
}

export default useLoader;
export * from './useLoader';
