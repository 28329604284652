import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=6dcf0763&scoped=true"
import script from "./LoginForm.vue?vue&type=script&lang=ts"
export * from "./LoginForm.vue?vue&type=script&lang=ts"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=6dcf0763&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcf0763",
  null
  
)

export default component.exports