import { GenericObjectType } from "~/utils/types";

/**
 * This function returns false if the user has not givent consent to trackings, so we use this function to block them.
 */
const ACCEPT_COOKIE_NAME = "SoleCookieAccept";
const ACCEPT_COOKIE_VALUE = "Y";
const canTrack = () => {
  const consentCookie = document && document.cookie.split(';').find(el => el.includes(ACCEPT_COOKIE_NAME));
  return consentCookie && consentCookie.includes(`=${ACCEPT_COOKIE_VALUE}`)
}

type SoleWindow = Window & typeof globalThis & { utag: GenericObjectType, utag_data: GenericObjectType}
export const trackView = (datas) => {
  if (!canTrack()) { console.log('TRACKING VIEW BLOCKED BY COOKIE CONSENT NEGATION'); return;}
  const w = window as SoleWindow;
  try {
    w.utag_data = { ...w.utag_data, ...datas};
    w.utag.view({ ...datas, no_view: 'no' })
    console.log('TRACKINGS:: view datas :: ',datas) /** @TODO remove */
  } catch (e) {
    console.error("trackView error : ", e);
  }
}

export const trackLink = (datas, callback?: Function, message?: string) => {
  if (!canTrack()) {
    console.log('TRACKING TRACK BLOCKED BY COOKIE CONSENT NEGATION');
    if (callback) {
      callback()
    }
    return;
  }
  /**
   * Using try catch block to avoid blocking the functionality because of tracking errors
   */
  try {
    const w = window as SoleWindow;
    w.utag?.link({ ...datas })
    console.log(`TRACKINGS:: ${message}`);
  } catch (e) {
    console.error('TRACKINGS ERROR:::',e)
  }
  if (callback) {
    callback()
  }
}
