import { trackLink } from "~/helpers/trackings";
import { getSectionName } from "~/helpers/trackings/helpers";

export const trackLogoClick = (routeName, subsection, callback?: Function) => {
  const sectionName = getSectionName(routeName);
  const obj = { link_id: `${sectionName}:${subsection}:logo` }
  trackLink(obj, callback, `Detected click on logo. Passing : ${JSON.stringify(obj)} `)
}

export const trackHeaderDropdownLink = (routeName, title, callback?: Function) => {
  const sectionName = getSectionName(routeName);
  const obj = { link_id: `${sectionName}:Header:${title}` } 
  trackLink(obj,  callback, `Detected click on dropdown link. Passing : ${JSON.stringify(obj)} `)
}