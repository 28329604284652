








import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import PageLoading from '~/components/GlobalComponents/PageLoading.vue';
export default defineComponent({
  name: 'MbooksLayout',
  components:{
    PageLoading
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  }
});
