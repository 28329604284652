

















import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import { ISTITUTIONAL_SITE } from '~/utils/constants/store';
import {trackLogoClick} from '~/helpers/trackings/layout';
export default defineComponent({
  name: 'HeaderLogo',
  components: { SfImage },
  setup() {
    const route = useRoute();
    return {
      addBasePath,
      logoUrl : ISTITUTIONAL_SITE,
      trackLogoClick,
      routeName: route.value.name
    };
  },
  methods:{
    goToLink(){
      window.location.href = ISTITUTIONAL_SITE;
    }
  }
});
