










import { defineComponent } from '@nuxtjs/composition-api'
import { PropType } from 'vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
    props:{
        loaderColor:{
            type: String,
            default: 'black'
        },
        classes: {
          type: Object as PropType<{root: string}>,
          default : ()=>{ return {root:''}}
        }
    },
    setup(){
        return {addBasePath}
    }
})
