














import { defineComponent, useContext } from "@nuxtjs/composition-api";
import HeaderLogo from "~/components/HeaderLogo.vue";
import HeaderDropdown from '~/components/HeaderDropdown.vue';
import { useUiState } from "~/composables";
import HeaderLogin from "./HeaderLogin.vue";
import ClientOnly from 'vue-client-only'
export default defineComponent({
  components: {
    HeaderLogo,
    HeaderDropdown,
    HeaderLogin,
    ClientOnly
  },
  setup() {
    const { hasLogin, hasDropdown } = useUiState();
    const { app} = useContext();
    return {
      hasLogin,
      cookies: app.$cookies.getAll(),
      hasDropdown
    }
  },
});
