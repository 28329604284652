













import { defineComponent, useRoute } from "@nuxtjs/composition-api";
import { addBasePath } from '~/helpers/addBasePath';
import PageLoading from '~/components/GlobalComponents/PageLoading.vue';
import { trackLogoClick } from '~/helpers/trackings/layout'
import { ISTITUTIONAL_SITE } from "~/utils/constants/store";

export default defineComponent({
  components:{
    PageLoading
  },
  setup() {
    const route = useRoute();
    const logoLink = ISTITUTIONAL_SITE;
    return { 
        route,
        addBasePath,
        logoLink,
        trackLogoClick
    }
  },
  methods: {
    goToLink(){
      window.location.href = this.logoLink
    }
  }
});
