




















import { defineComponent, useRoute, onMounted, ref, computed } from '@nuxtjs/composition-api'
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { SfIcon } from '@storefront-ui/vue';
import axios from 'axios';
import { HeaderDropdownLinkType } from '~/utils/types';
import { trackHeaderDropdownLink } from '~/helpers/trackings/layout';
/**
 * Not using SfDropdown because of header conflicts with positioning and rendering of the list
 */

export default defineComponent({
    components:{
        SfIcon
    },
    directives: { clickOutside },
    setup() {
        const isOpen = ref<Boolean>(false);
        const links = ref<HeaderDropdownLinkType[]>([]);
        const route = useRoute();
        const routeName = computed(()=>route.value.name)
        onMounted(async ()=>{
            const {data} = await axios.get('/sole-api/layout/getHeaderLinks');
            links.value = data;
        })
        return {
            isOpen,
            links,
            routeName,
            trackHeaderDropdownLink
        }
    },
    methods:{
        goToLink(url){
            window.location.href = url;
        }
    }
})
