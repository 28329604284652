













import { defineComponent } from '@nuxtjs/composition-api';
import Error from "~/components/404Error.vue";

export default defineComponent({
  components:{
    Error
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
});
