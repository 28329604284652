/**
 * mapping key to find in routename -> section name for tracking
 */
const trackingSectionNames = {
  'landing':'landing',
  'rinnovi':'rinnovi',
  'product':'product detail'
};

export const getSectionName = (routeName) => {
  let trackingSection = 'default';
  Object.keys(trackingSectionNames).some(key=>{
    if(routeName.toLowerCase().includes(key)){
      trackingSection = trackingSectionNames[key];
      return true;
    }else return false
  })
  return trackingSection;
}