






























import { defineComponent, ref, useContext } from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { PropType } from "vue";

export default defineComponent({
  props: {
    initialValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationPattern: {
      type: RegExp,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errorMessages:{
        type: Object as PropType<{
            required?: String,
            'validation-pattern'?: String,
        }>
    },
    tooltipText:{
        type: String
    },
    tooltipEvent:{
      type:String,
      default:'click'
    },
    tooltipPosition:{
      type:String,
      default: 'right'
    },
    customErrors:{
      type: Array,
      default:()=>{return []}
    }
  },
  setup(props, context) {
    const inputModel = ref<string>(props.initialValue);
    const { app : { i18n } } = useContext();
    
    const defaultErrorMessages = {
          required: i18n.t('This field is required'),
          'validation-pattern':  context.attrs.name && (props.label || props.placeholder)? i18n.t('{field} format not valid',{field : props.label??props.placeholder}) : i18n.t('Format validation error')
      }
    const errorMessagesMap = merge(defaultErrorMessages, props.errorMessages||{} );
    return {
      inputModel,
      errorMessagesMap
    };
  },
  data() {
    return {
      errors: [],
      showPwd: false,
      dirt: false
    }
  },
  methods: {
    checkValidation() {
        this.dirt = true;
      const value = this.inputModel;
      this.errors = [];
      if (this.required && (!value || value.trim() == "")) {
        this.errors.push("required");
      }
      if (this.validationPattern && value && !(this.validationPattern.test(this.inputModel)) ) {
        this.errors.push("validation-pattern");
      }

      if (this.errors.length > 0) {
        this.$emit("change", null);
      } else {
        this.$emit("change", this.inputModel);
      }
    },
  },
});
